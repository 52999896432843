<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Notifications</h3>
      </div>
      <el-form ref="form" :model="notification">
        <el-row>
          <el-col :span="24">
            <div class="card-body">
              <div class="p-4">
                <h4 class="fs-6 text-dark fw-medium">Notify me when</h4>
                <div class="mb-1">
                  <el-checkbox v-model="notification.when_recipient_opens">
                    When recipient opens document</el-checkbox
                  >
                </div>
                <div class="mb-1">
                  <el-checkbox v-model="notification.when_recipient_signs">
                    When recipient signs</el-checkbox
                  >
                </div>
                <div class="mb-1">
                  <el-checkbox v-model="notification.when_recipient_declines">
                    When recipient declines</el-checkbox
                  >
                </div>
                <!-- <div class="mb-1">
                  <el-checkbox v-model="notification.when_sender_voids">
                    When sender voids</el-checkbox
                  >
                </div> -->
                <div class="mb-1">
                  <el-checkbox
                    v-model="notification.when_a_document_is_approved_rejected"
                  >
                    When document is approved / rejected</el-checkbox
                  >
                </div>
                <div class="mb-1">
                  <el-checkbox v-model="notification.when_document_is_voided">
                    When document is voided</el-checkbox
                  >
                </div>
                <div class="mb-1">
                  <el-checkbox
                    v-model="notification.when_a_document_is_deleted"
                  >
                    When a document is deleted</el-checkbox
                  >
                </div>

                <div class="mb-1">
                  <el-checkbox v-model="notification.when_comment_is_posted">
                    When comment is posted</el-checkbox
                  >
                  <div
                    v-if="notification.when_comment_is_posted"
                    class="mt-1 ml-2"
                  >
                    <el-radio
                      v-model="notification.who_wants_to_get_comments"
                      label="1"
                      >Only me</el-radio
                    >
                    <el-radio
                      v-model="notification.who_wants_to_get_comments"
                      label="2"
                      >All active recipients</el-radio
                    >
                  </div>
                </div>
                <div class="mb-1">
                  <el-checkbox v-model="notification.when_recipient_is_cc_user">
                    When recipient is a cc user</el-checkbox
                  >
                </div>
                <div class="mb-1">
                  <el-checkbox v-model="notification.when_application_user_is_loggedin">
                    When application user is loggedin</el-checkbox
                  >
                </div>
                <!-- <div class="mb-1">
                  <el-checkbox
                    v-model="notification.when_a_signature_reassigns"
                  >
                    When a signer reassigns signature
                    responsibility</el-checkbox
                  >
                </div> -->
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button @click="goToDashboard">Cancel</el-button>
            <el-button
              type="primary"
              @click="onSubmit"
              >Save Changes</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DocumentNotifications",
  data() {
    return {
      loading: false,
      notification: {
        when_recipient_opens: false,
        when_recipient_signs: false,
        when_recipient_declines: false,
        when_sender_voids: false,
        when_a_document_is_approved_rejected: false,
        when_document_is_voided: false,
        when_a_document_is_deleted: false,
        when_comment_is_posted: false,
        when_a_signature_reassigns: false,
        who_wants_to_get_comments: "1",
        when_recipient_is_cc_user: false,
        when_application_user_is_loggedin: false,
      },
    };
  },
  computed: {
    ...mapGetters("settings", [
      "getUpdateUserNotificationsStatus",
      "getUserNotifications",
    ]),
  },
  async mounted() {
    this.loading = true;
    await this.fetchNotifications();
    this.setNotifications();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchNotifications: "settings/fetchUserNotifications",
    }),
    setNotifications() {
      if (this.getUserNotifications && this.getUserNotifications.data) {
        this.notification.when_recipient_opens =
          this.getUserNotifications.data.when_recipient_opens;
        this.notification.when_recipient_signs =
          this.getUserNotifications.data.when_recipient_signs;
        this.notification.when_recipient_declines =
          this.getUserNotifications.data.when_recipient_declines;
        this.notification.when_sender_voids =
          this.getUserNotifications.data.when_sender_voids;
        this.notification.when_a_document_is_approved_rejected =
          this.getUserNotifications.data.when_a_document_is_approved_rejected;
        this.notification.when_document_is_voided =
          this.getUserNotifications.data.when_document_is_voided;
        this.notification.when_a_document_is_deleted =
          this.getUserNotifications.data.when_a_document_is_deleted;
        this.notification.when_comment_is_posted =
          this.getUserNotifications.data.when_comment_is_posted;
        this.notification.when_a_signature_reassigns =
          this.getUserNotifications.data.when_a_signature_reassigns;
        this.notification.who_wants_to_get_comments =
          this.getUserNotifications.data.who_wants_to_get_comments;
        this.notification.when_recipient_is_cc_user =
          this.getUserNotifications.data.when_recipient_is_cc_user;
        this.notification.when_application_user_is_loggedin =
          this.getUserNotifications.data.when_application_user_is_loggedin
      }
    },
    async onSubmit() {
      try {
        this.loading = true;
        await this.$store.dispatch("settings/updateUserNotifications", {
          notification: this.notification,
        });
        if (this.getUpdateUserNotificationsStatus) {
          this.successNotify("Notification settings updated successfully");
          await this.fetchNotifications();
          this.setNotifications();
        } else {
          this.errorNotify("Error in updating notification settings");
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("onSubmit", err);
      }
    },
    goToDashboard() {
      this.setNotifications(); 
    },
  },
   beforeDestroy() {
    this.$store.commit(
    "settings/setUpdateUserNotificationsStatus", null,{root:true})
    this.$store.commit("settings/setUserNotifications", null,
     {
      root: true,
    }
    );
  },
};
</script>
